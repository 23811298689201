import $api from "@/api";
import $apiv3 from "@/apiv3";
import { defineStore, storeToRefs } from "pinia";
import type {
  AssignedUser,
  State,
  ThreadChatData,
  ThreadCommentData,
  ThreadDetail,
  AsyncThreadItem,
  ThreadItemMsg,
  ThreadLogData,
  ThreadSMSData,
  ThreadPostData,
  ThreadEmailData,
  Thread,
  AsyncThreadListItem,
  ThreadContact,
  SMSError,
  ThreadCustomData,
} from "./types/thread";
import type { ReplierAttachment } from "./types/email";
import {
  moveItemInArr,
  removeItemsWithCaution,
  rollbackItems,
} from "@/utils/helperFunction";
import { useUserStore } from "./user";
import { db, parentRef } from "@/firebase";
import { ref, set, onDisconnect } from "@firebase/database";
import { useEmailStore } from "./email";
import { useCustomStore } from "./customInbox";
import { differenceBy, orderBy, unionBy } from "lodash";
import router from "@/router";
import { useInboxStore } from "./inbox";
import useAxiosController from "@/functions/useAxiosController";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import { ThreadPriorityEnum } from "./enums/priority.enum";
import { InboxLabelEnum } from "./enums/label.enum";
import type { AxiosError } from "axios";
import type { ConversationListOrder } from "./types/root";
import { useRootStore } from "./root";

const { createReqController, cancelReq } = useAxiosController();

export const useThreadStore = defineStore({
  id: "thread",
  state: (): State => ({
    threads: [],
    threadsHasNextPage: false,
    threadsNextPageTokenMap: {},
    threadsDetailMap: {},
    activeThreadUsersMap: {},
    isThreadSharing: false,
    threadDetailPageCount: 1,
    newThreadCountMap: {},
  }),
  getters: {
    /**
     * Async thread list map
     */
    threadsMap() {
      const threadsMap: Record<string, AsyncThreadListItem> = {};

      this.threads.forEach((t) => {
        threadsMap[t.id] = t;
      });

      return threadsMap;
    },
  },
  actions: {
    _generateThreadListItems(threads: Thread[]): AsyncThreadListItem[] {
      if (!threads) {
        threads = [];
      }

      const _threads: AsyncThreadListItem[] = threads.map((t) => ({
        attachments: t.attachments,
        date: t.date,
        hasDraft: t.hasDraft,
        id: t.id,
        inboxId: t.inboxId.toString(),
        inboxType: t.inboxType,
        isArchived: t.isArchived,
        isDeleted: t.isDeleted,
        isRead: t.isRead,
        isSnoozed: t.isSnoozed,
        isSpam: t.isSpam,
        draftOnly: t.draftOnly,
        draftId: t.draftId,
        isStarred: t.isStarred,
        msgCount: t.messageCount ?? 0,
        snippet: t.snippet,
        snippetType: t.snippetType === 2 ? "note" : "reply",
        snoozedAt: t.snoozedAt,
        seenAt: t.seenAt,
        subject: t.subject,
        tags: t.tags,
        threadTitle: t.displayContact,
        assignedTo: t.assignedTo,
        priority: t.priority,
        ticketNumber: t.ticketNumber,
      }));

      return _threads;
    },
    _generateThreadItems(threadDetail: ThreadDetail): AsyncThreadItem[] {
      const threadItems: AsyncThreadItem[] = [];

      const sortedThreadDetailItem = orderBy(
        threadDetail.items,
        "timestamp",
        "asc"
      );
      const contact: ThreadContact = threadDetail.contacts[0];

      sortedThreadDetailItem.forEach((i, idx) => {
        let attachments: ThreadItemMsg["attachments"] = [];

        switch (i.type) {
          case "log": {
            const logData = i.data as ThreadLogData;
            threadItems.push({
              id: logData.id?.toString() ?? logData.body + logData.at + "",
              type: "log",
              content: logData.body,
              createdAt: logData.at,
            });
            break;
          }

          case "comment": {
            const noteData = i.data as ThreadCommentData;

            if (!noteData?.by) break;
            attachments = noteData.attachments;

            threadItems.push({
              type: "msg",
              attachments,
              deliveryStatus: "delivered",
              msgType: "note",
              user: {
                id: noteData.by.id.toString(),
                avatarTag: noteData.by.avatarTag,
                avatarUrl: noteData.by.avatarUrl ?? undefined,
                name:
                  noteData.by.displayName ??
                  noteData.by.firstname + " " + noteData.by.lastname,
                firstName: noteData.by.firstname,
                lastName: noteData.by.lastname,
              },
              content: noteData.body,
              createdAt: noteData.at,
              id: noteData.id.toString(),
              order: "sent",
              pinned: noteData.pinned,
            });

            break;
          }
          case "chat":
          case "sms":
          case "whatsapp":
          case "facebook-comments":
          case "facebook-dm":
          case "instagram-comments":
          case "instagram-dm":
          case "twitter":
          case "twitter-dm": {
            attachments = (i.data as Record<string, any>)
              .attachments as ThreadItemMsg["attachments"];

            // If url missing create a url with attachment id
            attachments.map((a) => {
              if (!a.url && i.data.id) {
                const _url =
                  import.meta.env.VITE_BASE_URL + "attachments/" + i.data.id;

                return {
                  ...a,
                  url: _url,
                };
              }

              return a;
            });

            // @ts-ignore
            const msgType:
              | ThreadItemMsg["msgType"]
              | "twitter-dm"
              | "instagram-dm"
              | "facebook-dm" = i.type;
            const content = (i.data as ThreadChatData).body?.toString();
            const id = (i.data as ThreadChatData).id.toString();
            let user: ThreadItemMsg["user"];
            const order: ThreadItemMsg["order"] =
              (i.data as ThreadChatData).direction === 0 ? "received" : "sent";
            const sentBy = (i.data as ThreadChatData).sentBy;
            const createdAt = (i.data as ThreadChatData).createdAt;
            const smsError: SMSError | undefined = (i.data as ThreadSMSData)
              .error;

            const dataDeliveryStatus = (i.data as ThreadChatData)
              .deliveryStatus;
            let deliveryStatus: ThreadItemMsg["deliveryStatus"] = "queued";

            if (
              dataDeliveryStatus === "24hourfailure" ||
              dataDeliveryStatus === "undelivered"
            ) {
              deliveryStatus = "failed";
            } else if (
              dataDeliveryStatus === "received" ||
              dataDeliveryStatus === "delivered"
            ) {
              deliveryStatus = "delivered";
            } else if (dataDeliveryStatus === "read") {
              deliveryStatus = "read";
            } else if (dataDeliveryStatus === "sent") {
              deliveryStatus = "sent";
            } else if (dataDeliveryStatus === "failed") {
              deliveryStatus = "failed";
            }

            const regex = /<span\b[^>]*>(?!\s*<\/span>)(.*?)<\/span>/;
            if (sentBy && sentBy.id) {
              // If span tag of avatar has no content it will force creation of custom avatar.
              const hasAvatarTagAnyContent = sentBy.avatarTag
                ? regex.test(sentBy.avatarTag)
                : false;
              user = {
                id: sentBy.id.toString(),
                avatarTag: hasAvatarTagAnyContent
                  ? sentBy.avatarTag
                  : undefined,
                avatarUrl: sentBy.avatarUrl ?? undefined,
                name: sentBy.firstname + " " + sentBy.lastname,
                firstName: sentBy.firstname,
                lastName: sentBy.lastname,
              };
            } else {
              if (contact) {
                const hasAvatarTagAnyContent = contact.avatarTag
                  ? regex.test(contact.avatarTag)
                  : false;
                user = {
                  id: contact.id.toString(),
                  avatarTag: hasAvatarTagAnyContent
                    ? contact.avatarTag
                    : undefined,
                  avatarUrl: contact.avatarUrl ?? undefined,
                  name: contact.firstname + " " + contact.lastname,
                  firstName: contact.firstname,
                  lastName: contact.lastname,
                };
              } else {
                user = {
                  id: nanoid(),
                  avatarTag: undefined,
                  avatarUrl: undefined,
                  name: "Unknown Sender",
                  firstName: "Unknown",
                  lastName: "Sender",
                };
              }
            }

            if (
              (i.type === "facebook-comments" &&
                threadDetail.inboxType === "facebook-comments") ||
              (i.type === "instagram-comments" &&
                threadDetail.inboxType === "instagram-comments") ||
              (i.type === "twitter" && threadDetail.inboxType === "twitter")
            ) {
              // create a post thread item for comments
              threadItems.push({
                id: (i.data as ThreadPostData).id.toString(),
                type: "post",
                postType:
                  i.type === "facebook-comments"
                    ? "facebook"
                    : i.type === "instagram-comments"
                    ? "instagram"
                    : i.type,
                data: i.data as ThreadPostData,
                createdAt: (i.data as ThreadPostData).createdAt,
              });

              break;
            }

            if (i.data && (i.data as ThreadSMSData).call) {
              const call = (i.data as ThreadSMSData).call;
              threadItems.push({
                type: "call",
                createdAt: createdAt,
                data: call,
                id: call?.id.toString() ?? "",
                user: user || undefined,
              });

              break;
            }

            // handle seen/nojtseen
            let isSeen: boolean | undefined = undefined;
            if (i.type === "chat" && order === "sent") {
              const unreadCountInt = threadDetail.unreadCount
                ? parseInt(threadDetail.unreadCount)
                : 0;

              if (idx >= threadDetail.items.length - unreadCountInt) {
                isSeen = false;
              } else {
                isSeen = true;
              }
            }

            threadItems.push({
              type: "msg",
              attachments,
              deliveryStatus,
              msgType:
                msgType === "twitter-dm"
                  ? "twitter"
                  : msgType === "instagram-dm"
                  ? "instagram"
                  : msgType === "facebook-dm"
                  ? "facebook"
                  : msgType,
              user,
              content,
              createdAt,
              id,
              order,
              isSeen,
              smsError,
            });
            break;
          }
          case "email": {
            threadItems.push({
              id: (i.data as ThreadEmailData).id.toString(),
              type: "email",
              data: i.data as ThreadEmailData,
              createdAt: (i.data as ThreadEmailData).date,
            });
            break;
          }

          case "custom": {
            threadItems.push({
              id: (i.data as ThreadCustomData).id.toString(),
              type: "custom",
              data: i.data as ThreadCustomData,
              createdAt: (i.data as ThreadCustomData).createdAt,
            });
            break;
          }
        }
      });

      return threadItems;
    },
    /**
     * Creates a set of data for quick msg insertion.
     * And also push it in the threadItems and updates threads list snippet.
     *
     * Returns `tempId` for updateAsyncMsgThreadItem.
     */
    asyncInsertMsgThreadItem(
      threadId: string,
      msgType:
        | "facebook"
        | "instagram"
        | "twitter"
        | "whatsapp"
        | "chat"
        | "sms"
        | "note"
        | "twitterdm",
      content: string,
      /**
       * If not provided, content becomes snippet.
       */
      snippet?: string
    ): { tempId: string } {
      const userStore = useUserStore();
      const threadStore = useThreadStore();

      const { user } = storeToRefs(userStore);
      const { threads } = storeToRefs(threadStore);

      const tempId = nanoid();

      this.threadsDetailMap[threadId]?.threadItems.push({
        type: "msg",
        msgType,
        attachments: [],
        content,
        createdAt: dayjs().tz().toISOString(),
        deliveryStatus: "queued",
        id: tempId,
        order: "sent",
        isSeen: msgType === "chat" ? false : undefined,
        user: {
          id: user.value!.id.toString(),
          avatarTag: user.value!.avatarTag,
          avatarUrl: user.value!.avatarUrl ?? undefined,
          firstName: user.value!.firstname,
          lastName: user.value!.lastname,
          name: user.value!.firstname + " " + user.value!.lastname,
        },
      });

      // # Updating snippet in threadList
      let foundThreadIdx = 0;
      const foundThread = threads.value.find((t, idx) => {
        if (t.id.toString() === threadId) {
          foundThreadIdx = idx;
          return t;
        }
      });

      if (foundThread) {
        foundThread.snippet = snippet ?? content;
        foundThread.snippetType = msgType === "note" ? "note" : "reply";
        foundThread.date = dayjs().tz().toISOString();

        if (msgType !== "note") {
          // update its position as well.
          moveItemInArr(threads.value, foundThreadIdx, 0);
        }
      }

      return { tempId };
    },
    /**
     * Updates the `id`, `deliveryStatus`, `createdAt`, `attachments` property with actual data.
     *
     * Only use after api execution is fully done.
     */
    updateAsyncMsgThreadItem(
      type: "success" | "failed",
      tempMsgId: string,
      threadId: string,
      msgId?: string,
      data?:
        | {
            dataType: "chat";
            attachment: {
              id: number;
              mime_type: string;
              url: string;
              name: string;
              size: string;
            }[];
            [key: string]: any;
          }
        | {
            dataType: "note";
            attachmentIds?: number[];
            content?: string;
          }
        | {
            dataType: "sms";
            attachments?: { media_url: string; content_type: string }[];
          }
        | {
            dataType: "smsNew";
            attachments?: { url: string; contentType: string }[];
          }
        | {
            dataType: "whatsapp";
            attachments?: {
              url: string;
              contentType: string;
              extension: string;
            }[];
          }
        | {
            dataType: "whatsappScheduled";
            attachments: {
              contentType: string;
              url: string;
              extension: string;
            }[];
          }
        | {
            dataType: "meta";
            attachment: {
              filename: string;
              attachment_url: string;
              attachment_type: string;
              extension: string;
              filesize: string;
              id: string;
            }[];
          }
        | {
            dataType: "twitter";
            attachments: {
              content_type: string;
              media_url: string;
            };
          }
    ) {
      // Currently this function only updates id and deliveryStatus. Require other data from backend.
      if (this.threadsDetailMap[threadId]) {
        // Find the thread in items
        const foundThreadItem = this.threadsDetailMap[
          threadId
        ]?.threadItems.find((i) => {
          if (i.type === "msg" && i.id === tempMsgId) {
            return i;
          }
        });

        // For msg update id, deliveryStatus and attachments if success.
        if (foundThreadItem?.type === "msg") {
          if (type === "success" && msgId) {
            foundThreadItem.id = msgId;
            foundThreadItem.deliveryStatus =
              data?.dataType === "whatsapp" ||
              data?.dataType === "whatsappScheduled"
                ? "sent"
                : "delivered";
            // adding chat attachments
            if (data?.dataType === "chat") {
              data.attachment.forEach((att) => {
                foundThreadItem.attachments.push({
                  contentType: att.mime_type as any,
                  url: att.url,
                  id: att.id,
                  filename: att.name,
                  filesize: Number(att.size),
                  extension: "",
                });
              });
            } else if (
              data?.dataType === "whatsapp" ||
              (data?.dataType === "whatsappScheduled" &&
                data.attachments &&
                Object.keys(data.attachments).length)
            ) {
              data.attachments?.forEach((att) => {
                foundThreadItem.attachments.push({
                  contentType: att.contentType as any,
                  url: att.url,
                  extension: "",
                  filename: "whatsapp-attachment",
                  filesize: 0,
                  id: nanoid(),
                });
              });
            } else if (data?.dataType === "meta") {
              data.attachment.forEach((att) => {
                foundThreadItem.attachments.push({
                  contentType: att.attachment_type as any,
                  url: att.attachment_url,
                  id: att.id,
                  filename: att.filename,
                  filesize: Number(att.filesize),
                  extension: att.extension,
                });
              });
            } else if (data?.dataType === "note") {
              data.attachmentIds?.forEach((id) => {
                const url = import.meta.env.VITE_BASE_URL + "attachments/" + id;

                foundThreadItem.attachments.push({
                  contentType: "",
                  extension: "",
                  filename: "note-attachment",
                  filesize: 0,
                  url: url,
                  id: nanoid(),
                });
              });

              if (data.content) {
                foundThreadItem.content = data.content;
              }
            } else if (data?.dataType === "sms") {
              data.attachments?.forEach((att) => {
                foundThreadItem.attachments.push({
                  contentType: att.content_type as any,
                  url: att.media_url,
                  extension: "",
                  filename: "sms-attachment",
                  filesize: 0,
                  id: nanoid(),
                });
              });
            } else if (data?.dataType === "smsNew") {
              data.attachments?.forEach((att) => {
                foundThreadItem.attachments.push({
                  contentType: att.contentType as any,
                  url: att.url,
                  extension: "",
                  filename: "sms-attachment",
                  filesize: 0,
                  id: nanoid(),
                });
              });
            } else if (
              data?.dataType === "twitter" &&
              Object.keys(data.attachments).length
            ) {
              foundThreadItem.attachments = [
                {
                  contentType:
                    (data.attachments.content_type as any) === "photo"
                      ? "image"
                      : "video/mp4",
                  url: data.attachments.media_url,
                  extension: "",
                  filename: "twitter-attachment",
                  filesize: 0,
                  id: nanoid(),
                },
              ];
            }
          } else {
            if (this.threadsDetailMap[threadId]) {
              foundThreadItem.deliveryStatus = "failed";

              // Reverting the note in case of failed edit
              if (data?.dataType === "note") {
                if (data.content) {
                  foundThreadItem.content = data.content;
                }
              }
            }
          }
        }
      }
    },
    async fetchInboxThreads(
      inboxIds: string[],
      label: InboxLabelEnum,
      page = 1,
      search?: string,
      order?: ConversationListOrder
    ): Promise<void> {
      const userStore = useUserStore();
      const { conversationListOrder, conversationListOrder2 } = useRootStore();
      const { userSettings } = userStore;
      const resultsPerPage = userSettings?.resultsPerPage
        ? parseInt(userSettings.resultsPerPage)
        : 20;

      let listOrder: ConversationListOrder = conversationListOrder2 ?? "newest";
      if (
        (label === InboxLabelEnum.all ||
          label === InboxLabelEnum.mine ||
          label === InboxLabelEnum.assigned ||
          label === InboxLabelEnum.unassigned) &&
        conversationListOrder
      ) {
        listOrder = conversationListOrder;
      }

      const params: Record<string, any> = {
        inboxIds: inboxIds,
        labelId: label,
        order: order ?? listOrder,
        limit: resultsPerPage,
        pageToken: this.threadsNextPageTokenMap[page],
      };

      let url = "/conversations";

      if (search) {
        url += "?" + search;
      }

      // First cancel the previous request if happening
      cancelReq(url);

      // create new request controller for axios
      const controller = createReqController(url);
      try {
        const res = await $apiv3.get(url, {
          params,
          signal: controller.signal,
        });
        const { threads, nextPageToken } = res.data;
        this.threads = this._generateThreadListItems(threads);
        this.threadsHasNextPage = !!nextPageToken;
        this.threadsNextPageTokenMap[page + 1] = nextPageToken;
      } catch (err) {
        if ((err as AxiosError).code == "ERR_CANCELED") {
          return;
        }
        throw err;
      }
    },
    async fetchInboxThreadById(
      id: number,
      inboxId: string,
      labelId: InboxLabelEnum
    ): Promise<void> {
      const emailStore = useEmailStore();
      const inboxStore = useInboxStore();
      const customStore = useCustomStore();
      const threadStore = useThreadStore();

      const { isInboxUniversal } = storeToRefs(inboxStore);
      const { threads } = storeToRefs(threadStore);

      const url =
        inboxId === "me"
          ? `/conversations/${id}`
          : `/inboxes/${inboxId}/conversations/${id}`;

      const res = await $apiv3.get(url, {
        params: {
          labelId,
        },
      });

      if (res.data.status === "error") {
        if (res.data.message.includes("permission")) {
          const inboxId = router.currentRoute.value.params.id;
          const path = isInboxUniversal
            ? "/universal/me/all?filter=open"
            : `/inboxes/${inboxId}/all`;

          // redirect user to thread lists
          router.replace(path);
        }

        throw new Error(res.data.message);
      }

      const threadItems = this._generateThreadItems(res.data);

      // Updating thread list snippet.
      const foundThread = threads.value.find((t) => t.id === id);
      if (foundThread) {
        foundThread.snippet = res.data?.snippet;
      }

      // Pushing the data in thread map
      this.threadsDetailMap[id] = {
        threadDetail: {
          ...res.data,
          id, // No id is present in coming data,
          contact: res.data.contacts[0] ?? [],
          mailbox_id: inboxId,
        },
        threadItems: orderBy(threadItems, "createdAt", "asc"),
      };

      // Pushing the emailDraft in separate emailStore variable
      const draft: ThreadDetail["drafts"] | undefined = res.data.drafts;
      emailStore.emailDraftsMap[id] = [];
      customStore.customDraftsMap[id] = [];

      draft?.forEach((d) => {
        emailStore.emailDraftsMap[id]?.push({
          bcc: d.bcc,
          cc: d.cc,
          draftID: d.id,
          files: d.attachments as ReplierAttachment[],
          from: d.from,
          html: d.content.html.body ?? "",
          inReplyTo: d.inReplyTo,
          mailboxID: d.inboxId,
          subject: d.subject,
          text: d.text,
          threadID: d.threadId,
          to: {
            ...d.replyTo,
            ...d.to,
          },
          replyAll: d.replyAll,
          forwardOf: d.forwardOf?.toString(),
          shared: d.shared,
          owner: d.owner,
          consecutiveCall: true,
          sharedUsers: d.sharedUsers,
        });
      });
    },
    async getContactData(id: string): Promise<ThreadContact> {
      const res = await $api.get("/contacts/get-v3.php", {
        params: {
          id,
        },
      });
      if (res.data.status === "error") {
        throw new Error();
      }
      const contactData: ThreadContact = res.data.data as ThreadContact;

      return contactData;
    },
    async fetchNextPageInboxThreadById(
      id: number,
      inboxId: string,
      labelId: InboxLabelEnum,
      pageToken: string
    ): Promise<boolean | void> {
      const res = await $apiv3.get(`/inboxes/${inboxId}/conversations/${id}`, {
        params: {
          pageToken,
          labelId,
        },
      });

      if (res.data.status === "error") {
        throw new Error();
      }

      const threadData = this.threadsDetailMap[id]?.threadDetail;

      if (threadData) {
        const doesNewItemsExist =
          differenceBy(
            (res.data as ThreadDetail).items,
            threadData.items,
            (item) => (item.data as any).id
          ).length > 0;

        if (doesNewItemsExist) {
          // Pushing the new items data in existing thread items
          this.threadsDetailMap[id]!.threadDetail.items =
            threadData.items.concat(res.data.items);

          // Populating threadItems
          const threadItems = this._generateThreadItems(
            this.threadsDetailMap[id]!.threadDetail
          );
          this.threadsDetailMap[id]!.threadItems = unionBy(
            this.threadsDetailMap[id]!.threadItems,
            threadItems,
            "id"
          );

          // Populating maxDate for pagination
          this.threadsDetailMap[id]!.threadDetail.nextPageToken =
            res.data.nextPageToken;

          return true;
        }
      }
    },
    async updateThreadReadStatus(
      status: "read" | "unread",
      threadIds: number[],
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */
      threadIdsMap: {
        [inboxId: string]: number[];
      }
    ) {
      const url =
        status === "read"
          ? "/unifiedv2/readThreads.php"
          : "/unifiedv2/unreadThreads.php";

      // find and update the thread read status first for asynchronous action
      this.threads.forEach((thread) => {
        if (threadIds.includes(thread.id)) {
          thread.isRead = status === "read" ? true : false;
        }
      });

      try {
        const res = await $api.post(url, {
          mailboxThreadMap: threadIdsMap,
        });

        if (res.data.status === "error") {
          throw new Error();
        }
      } catch (err) {
        // Roll back the changes
        this.threads.forEach((thread) => {
          if (threadIds.includes(thread.id)) {
            thread.isRead = status === "read" ? false : true;
          }
        });

        throw err;
      }
    },
    async closeThreads(
      threadIds: number[],
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */
      threadIdsMap: {
        [inboxId: string]: number[];
      }
    ) {
      // Remove the thread
      const { filteredList, removedItemsObj } = removeItemsWithCaution(
        this.threads,
        threadIds
      );
      this.threads = filteredList;

      try {
        const res = await $api.post("/unifiedv2/archiveThreads.php", {
          mailboxThreadMap: threadIdsMap,
        });

        if (res.data.status === "error") {
          throw new Error();
        }

        // Remove the threadDetailData so that it refetches
        threadIds.forEach((id) => {
          delete this.threadsDetailMap[id];
        });
      } catch (err) {
        // Rollback the changes.
        this.threads = rollbackItems(this.threads, removedItemsObj);
        throw err;
      }
    },
    /**
     * Note: Donot support universal as of now.
     */
    async doneThreads(
      threadIds: number[],
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */
      threadIdsMap: {
        [inboxId: string]: number[];
      }
    ) {
      // Remove the thread
      const { filteredList, removedItemsObj } = removeItemsWithCaution(
        this.threads,
        threadIds
      );
      this.threads = filteredList;

      try {
        const res = await $api.post("/doneThreads.php", {
          threadIDs: threadIds,
          mailboxID: Object.keys(threadIdsMap)[0],
        });

        if (res.data.status === "error") {
          throw new Error();
        }

        // Remove the threadDetailData so that it refetches
        threadIds.forEach((id) => {
          delete this.threadsDetailMap[id];
        });
      } catch (err) {
        // Rollback the changes.
        this.threads = rollbackItems(this.threads, removedItemsObj);
        throw err;
      }
    },
    async moveThreads(
      threadIds: number[],
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */
      threadIdsMap: {
        [inboxId: string]: number[];
      },
      isFromSpam?: boolean
    ) {
      // Remove the threads first
      const { filteredList, removedItemsObj } = removeItemsWithCaution(
        this.threads,
        threadIds
      );
      this.threads = filteredList;

      try {
        const res = await $api.post("/unifiedv2/restoreThreads.php", {
          mailboxThreadMap: threadIdsMap,
          unspam: isFromSpam ? isFromSpam : false,
        });

        if (res.data.status === "error") {
          throw new Error();
        }

        // Remove the threadDetailData so that it refetches
        threadIds.forEach((id) => {
          delete this.threadsDetailMap[id];
        });
      } catch (err) {
        // Rollback the changes.
        this.threads = rollbackItems(this.threads, removedItemsObj);
        throw err;
      }
    },
    async moveThreadsToInbox(
      threadIds: number[],
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */
      threadIdsMap: {
        [inboxId: string]: number[];
      },
      targetInboxId: string
    ) {
      // Note: No rollback needed since we have a modal
      // that waits for the response from the server.
      const res = await $api.post("/unifiedv2/moveThreads.php", {
        mailboxThreadMap: threadIdsMap,
        targetMailboxId: targetInboxId,
      });

      if (res.data.status === "error") {
        throw new Error();
      }

      // Remove the threadDetailData so that it refetches
      threadIds.forEach((id) => {
        delete this.threadsDetailMap[id];
      });

      // Remove the threads
      const { filteredList } = removeItemsWithCaution(this.threads, threadIds);

      this.threads = filteredList;
    },
    async spamThreads(
      threadIds: number[],
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */ threadIdsMap: {
        [inboxId: string]: number[];
      }
    ) {
      // Remove the threads first
      const { filteredList, removedItemsObj } = removeItemsWithCaution(
        this.threads,
        threadIds
      );
      this.threads = filteredList;

      try {
        const res = await $api.post("/unifiedv2/spamThreads.php", {
          mailboxThreadMap: threadIdsMap,
        });

        if (res.data.status === "error") {
          throw new Error();
        }

        // Remove the threadDetailData so that it refetches
        threadIds.forEach((id) => {
          delete this.threadsDetailMap[id];
        });
      } catch (err) {
        // Rollback the changes.
        this.threads = rollbackItems(this.threads, removedItemsObj);
        throw err;
      }
    },
    removeItemFromThread(itemId: string, threadId: string) {
      const newThreadItems: AsyncThreadItem[] = this.threadsDetailMap[
        threadId
      ]!.threadItems.filter((item) => item.id !== itemId);
      const threadDetails: ThreadDetail =
        this.threadsDetailMap[threadId]!.threadDetail;
      this.threadsDetailMap[threadId] = {
        threadDetail: threadDetails,
        threadItems: newThreadItems,
      };
    },
    async moveToANewConversation(
      messageId: string,
      threadId: string,
      inboxId: string,
      subject: string
    ) {
      const res = await $api.post("/moveToNewConversation.php", {
        messageId,
        subject,
        threadId,
        mailboxId: inboxId,
      });

      if (res.data.status === "error") {
        throw new Error();
      }

      this.removeItemFromThread(messageId, threadId);
    },
    renameConversation(threadId: string, subject: string) {
      const threadItems: AsyncThreadItem[] =
        this.threadsDetailMap[threadId]!.threadItems;
      const newThreadDetails: ThreadDetail =
        this.threadsDetailMap[threadId]!.threadDetail;
      newThreadDetails.subject = subject;
      this.threadsDetailMap[threadId] = {
        threadDetail: newThreadDetails,
        threadItems: threadItems,
      };
    },
    async changeSubjectOfConversation(
      threadId: string,
      inboxId: string,
      subject: string
    ) {
      const res = await $api.post("/renameEmailThread.php", {
        threadId,
        inboxId,
        subject,
      });

      if (res.data.status === "error") {
        throw new Error();
      }

      this.renameConversation(threadId, subject);
    },
    async updateThreadStarredStatus(
      threadIds: number[],
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */
      threadIdsMap: {
        [inboxId: string]: number[];
      },
      status: "star" | "unstar"
    ) {
      const url =
        status === "star"
          ? "/unifiedv2/starThreads.php"
          : "/unifiedv2/unstarThreads.php";

      // find and update the thread starred status
      this.threads.forEach((thread) => {
        if (threadIds.includes(thread.id)) {
          thread.isStarred = status === "star" ? true : false;
          if (this.threadsDetailMap[thread.id]) {
            this.threadsDetailMap[thread.id]!.threadDetail.isStarred =
              status === "star" ? true : false;
          }
        }
      });

      try {
        const res = await $api.post(url, {
          mailboxThreadMap: threadIdsMap,
        });

        if (res.data.status === "error") {
          throw new Error();
        }
      } catch (err) {
        // Rollback the changes
        this.threads.forEach((thread) => {
          if (threadIds.includes(thread.id)) {
            thread.isStarred = status === "star" ? false : true;
          }
        });

        throw err;
      }
    },
    async snoozeThreads(
      threadIds: number[],
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */
      threadIdsMap: {
        [inboxId: string]: number[];
      },
      snoozeTill: string
    ) {
      // Remove the threads first
      const { filteredList, removedItemsObj } = removeItemsWithCaution(
        this.threads,
        threadIds
      );
      this.threads = filteredList;

      try {
        const res = await $api.post("/unifiedv2/snoozeThreads.php", {
          mailboxThreadMap: threadIdsMap,
          snoozeTill,
        });

        if (res.data.status === "error") {
          throw new Error();
        }

        // Remove the threadDetailData so that it refetches
        threadIds.forEach((id) => {
          delete this.threadsDetailMap[id];
        });
      } catch (err) {
        // Rollback the changes.
        this.threads = rollbackItems(this.threads, removedItemsObj);
        throw err;
      }
    },
    async mergeThreads(
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */
      threadIdsMap: {
        [inboxId: string]: number[];
      }
    ) {
      const res = await $api.post("/unifiedv2/mergeThreads.php", {
        mailboxThreadMap: threadIdsMap,
      });

      if (res.data.status === "error") {
        throw new Error();
      }
    },
    async assignThreads(
      userId: number | null,
      threadIds: number[],
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */
      threadIdsMap: {
        [inboxId: string]: number[];
      }
    ) {
      // find the user in the teamMates
      const userStore = useUserStore();
      const foundUser = userStore.teamMates.find((t) => t.id === userId);

      // data for old assigned user which will be needed for rollback
      const rollbackAssignedUserData: {
        [threadId: number | string]: AssignedUser | null | undefined;
      } = {};

      // update the assignedTo property if userId is not null
      threadIds.forEach((t) => {
        const foundThread = this.threads.find((thread) => thread.id === t);

        if (foundThread) {
          rollbackAssignedUserData[t] = foundThread.assignedTo;

          if (foundUser) {
            foundThread.assignedTo = {
              avatarTag: foundUser.avatarTag,
              avatarUrl: foundUser.avatarUrl,
              email: foundUser.email,
              firstname: foundUser.firstname,
              id: foundUser.id,
              lastname: foundUser.lastname,
              role: foundUser.role,
              displayName: foundUser.displayName,
            };
          } else {
            foundThread.assignedTo = null;
          }

          // also update the threadsDetailMap[threadID] currentAssignment
          if (this.threadsDetailMap[t]?.threadDetail.assignedTo) {
            this.threadsDetailMap[t]!.threadDetail.assignedTo =
              foundThread.assignedTo;
          }
        }
      });

      try {
        const payload: {
          assignedUser?: number;
          mailboxThreadMap?: Record<string, number[]>;
        } = {
          mailboxThreadMap: threadIdsMap,
        };

        if (userId) {
          payload.assignedUser = userId;
        }

        const res = await $api.post("/unifiedv2/assignThreads.php", payload);

        if (res.data.status === "error") {
          throw new Error();
        }
      } catch (err) {
        // Rollback the changes
        this.threads = this.threads.map((t) => {
          const assignedTo = rollbackAssignedUserData[t.id] ?? t.assignedTo;

          // also update the threadsDetailMap[threadID] currentAssignment
          if (this.threadsDetailMap[t.id]?.threadDetail.assignedTo) {
            this.threadsDetailMap[t.id]!.threadDetail.assignedTo =
              rollbackAssignedUserData[t.id]!;
          }

          return {
            ...t,
            assignedTo,
          };
        });

        throw err;
      }
    },
    async setThreadPriority(
      threadId: number,
      priority: string,
      mailboxThreadMap: {
        [inboxId: string]: number[];
      }
    ) {
      const foundThread = this.threads.find((thread) => thread.id === threadId);
      if (foundThread != undefined) {
        if (priority == "1") {
          foundThread.priority = {
            source: "automated",
            value: ThreadPriorityEnum["high"],
          };
        } else if (priority == "2") {
          foundThread.priority = {
            source: "automated",
            value: ThreadPriorityEnum["medium"],
          };
        } else if (priority == "3") {
          foundThread.priority = {
            source: "automated",
            value: ThreadPriorityEnum["choose"],
          };
        } else if (priority == "4") {
          foundThread.priority = {
            source: "automated",
            value: ThreadPriorityEnum["low"],
          };
        }
      }

      const inboxId = +Object.keys(mailboxThreadMap)[0];
      const res = await $api.post("/threads/priority/setThreadPriority.php", {
        threadIds: [threadId],
        mailboxId: inboxId,
        priority,
      });

      if (res.data.status === "error") {
        throw new Error();
      }
    },
    async deleteThreads(
      threadIds: number[],
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */
      threadIdsMap: {
        [inboxId: string]: number[];
      },
      isDraft?: boolean
    ) {
      // Remove the threads first
      const { filteredList, removedItemsObj } = removeItemsWithCaution(
        this.threads,
        threadIds
      );
      this.threads = filteredList;

      try {
        if (isDraft) {
          const promises: Promise<void>[] = [];

          Object.keys(threadIdsMap).forEach((inboxId) => {
            const payload = {
              threadIds: threadIdsMap[inboxId],
              mailboxId: inboxId,
            };

            promises.push($api.post("/discardThreadDraft", payload));
          });

          await Promise.all(promises);
        } else {
          const res = await $api.post("/unifiedv2/trashThreads.php", {
            mailboxThreadMap: threadIdsMap,
          });

          if (res.data.status === "error") {
            throw new Error();
          }
        }

        // Remove the threadDetailData so that it refetches
        threadIds.forEach((id) => {
          delete this.threadsDetailMap[id];
        });
      } catch (err) {
        // Rollback the changes.
        this.threads = rollbackItems(this.threads, removedItemsObj);
        throw err;
      }
    },
    async permanentlyDeleteThreads(threadIds: number[]) {
      // Remove the threads first
      const { filteredList, removedItemsObj } = removeItemsWithCaution(
        this.threads,
        threadIds
      );
      this.threads = filteredList;

      try {
        const res = await $apiv3.put("/conversations/action/delete", {
          ids: threadIds,
        });

        if (res.data.status === "error") {
          throw new Error();
        }

        // Remove the threadDetailData so that it refetches
        threadIds.forEach((id) => {
          delete this.threadsDetailMap[id];
        });
      } catch (err) {
        // Rollback the changes.
        this.threads = rollbackItems(this.threads, removedItemsObj);
        throw err;
      }
    },
    async mailTranscript(threadId: string, inboxId: string) {
      const res = await $api.post("/transcripts/send.php", {
        mailboxId: inboxId,
        threadId: threadId,
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },
    /**
     * Set logged in user on firebase "/viewing user" db for a thread.
     */
    async toggleViewingUserOnThread(
      threadId: number,
      type: "add" | "remove"
    ): Promise<void> {
      const userStore = useUserStore();
      const { viewingUser } = userStore;

      if (!viewingUser) return;

      const dbRef = ref(
        db,
        parentRef + `/Thread-${threadId}/viewing user/${viewingUser.id}`
      );

      if (type === "add") {
        if (userStore.viewingUser) {
          userStore.viewingUser.time = dayjs().utc().valueOf();
        }
        set(dbRef, viewingUser);
        const updatedViewingUser = Object.assign({}, viewingUser);
        updatedViewingUser.status = "Offline";
        onDisconnect(dbRef).update(updatedViewingUser);
      } else {
        const updatedViewingUser = Object.assign({}, viewingUser);
        updatedViewingUser.status = "Offline";
        set(dbRef, updatedViewingUser);
      }
    },

    async updateLastViewing(threadId: number): Promise<void> {
      const userStore = useUserStore();
      const { viewingUser } = userStore;
      if (!viewingUser) return;
      const dbRef = ref(
        db,
        parentRef + `/Thread-${threadId}/viewing user/${viewingUser.id}/time`
      );
      set(dbRef, dayjs().utc().valueOf());
    },
    async createView(name: string, inboxId: string, base: string) {
      const inboxStore = useInboxStore();
      const res = await $api.post("/save-view.php", {
        base_64: base,
        mailbox_id: inboxId,
        name,
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error("Limit exceeded");
      }

      inboxStore.inboxViewsMap[inboxId]?.push({
        id: data,
        mailboxId: Number(inboxId),
        name: name,
        pinned: "1",
        searchQuery: base,
      });
    },
  },
});
