<template>
  <div v-if="isOpen" id="modal" data-testid="modal">
    <div
      class="modal-content tx-14"
      :class="isAiModal ? 'ai-modal' : ''"
      :style="{
        width,
        height,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        ...contentStyles,
      }"
    >
      <template v-if="noBoiler">
        <slot></slot>
      </template>

      <template v-else>
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="title"></slot>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            data-testid="hwmodal-close"
            @click="closeModal"
          >
            <span aria-hidden="true">x</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="content"></slot>
        </div>
        <div class="modal-footer">
          <slot name="actions"></slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import { useRootStore } from "@/stores/root";
import { useIntegrationStore } from "@/stores/integration";

const props = defineProps<{
  open: boolean;
  modalFor?: "root" | "integration";
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  noBoiler?: boolean;
  isAiModal?: boolean;
  contentStyles?: Record<string, any>;
}>();

const rootStore = useRootStore();
const integrationStore = useIntegrationStore();

const isOpen = ref(props.open);

watch(
  () => props.open,
  (open) => {
    isOpen.value = open;
    if (open === false) {
      closeModal();
    }
  }
);

const closeModal = () => {
  isOpen.value = false;
  if (!props.modalFor || props.modalFor === "root") {
    rootStore.setModal(null);
  } else {
    integrationStore.setModal(null);
  }
};
</script>

<style>
#modal {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-modal {
  display: inline;
  position: relative;
  border: none;
  border-radius: 0.75rem;
  align-items: center;
  justify-content: center;
}

.ai-modal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  border: 1px solid transparent;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(45deg, rgb(255, 155, 220), rgb(158, 128, 234)) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
</style>
