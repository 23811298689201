import $api from "@/api";
import $apiv3 from "@/apiv3";
import type { State } from "./types/ai";
import { defineStore } from "pinia";
import type { InboxType } from "./types/inbox";

export const useAIStore = defineStore({
  id: "ai",
  state: (): State => ({
    autopilotSuggestions: null,
    aiReplyApiStatus: 1,
    draftSuggestionMap: {},
    threadSuggestionMap: {},
    threadReplyMap: {},
    editorAiTabMap: {},
  }),
  actions: {
    async rephraseText(
      text: string,
      tone: string,
      language: string
    ): Promise<string> {
      const res = await $apiv3.post("/ai/rephrase", {
        message: text,
        tone,
        language,
      });

      const { message } = res.data;

      return message;
    },
    async rephraseLanguage(language: string): Promise<void> {
      await $api.post("/ai/setRephraseLanguage", {
        language,
      });
    },
    async summarizeChat(threadId: string, inboxId: string): Promise<string> {
      const res = await $apiv3.post("/ai/summary", {
        conversationId: threadId,
        inboxId,
      });

      const { summary } = res.data;

      return summary;
    },
    async getAiReply(
      conversationId: string,
      inboxId: string,
      suggestions: string,
      draft: string,
      isComposer: boolean,
      draftId?: string
    ): Promise<void> {
      this.aiReplyApiStatus = 1;

      try {
        const res = await $apiv3.post("/ai/reply", {
          conversationId,
          inboxId,
          suggestions,
          draft,
          isComposer,
        });

        const { body } = res.data;

        let key: string = conversationId;
        if (isComposer && draftId) {
          key = draftId;
        }

        if (this.threadReplyMap[key] === undefined) {
          this.threadReplyMap[key] = [body];
        } else {
          this.threadReplyMap[key].push(body);
        }
      } catch {
        this.aiReplyApiStatus = 0;
      }
    },
    async getAutoPilotSuggestions(
      text: string,
      isHtml: boolean,
      inboxId: number,
      inboxType: InboxType,
      draftId?: number,
      threadId?: string
    ): Promise<void> {
      const res = await $apiv3.post("/ai/suggest", {
        text,
        isHtml,
        inboxId,
      });

      if (res.data.status === "success") {
        if (
          (inboxType === "mail" ||
            inboxType === "fb-feed" ||
            inboxType === "instagram") &&
          draftId
        ) {
          this.draftSuggestionMap[draftId] = res.data.data;
        } else if (threadId) {
          this.threadSuggestionMap[threadId] = res.data.data;
        }
      }
    },

    async getTone(message: string): Promise<string> {
      const res = await $apiv3.post("/ai/tones", {
        message,
      });

      const { tone } = res.data;

      return tone;
    },
    async logAiReplyFeedback(
      conversationId: number,
      emailId: number,
      inboxId: number,
      feedback: number,
      content: string
    ): Promise<void> {
      await $apiv3.post("/ai/reply/feedback", {
        conversationId,
        emailId,
        inboxId,
        feedback,
        content,
      });
    },
    async setUserOnboarded(state: number, flag: number): Promise<void> {
      if ((flag === 0 && state <= 5) || flag === 1) {
        await $api.post("/ai/setUserOnboarded", {
          state,
          flag,
        });
      }
    },
    async logAiReplyDraftCreation(
      threadId: number,
      inboxId: number
    ): Promise<void> {
      await $apiv3.post("/ai/reply/logUsage", {
        conversationId: threadId,
        inboxId,
        actionType: 7,
      });
    },
  },
});
